import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class MessageBox {

  constructor(private toast: ToastController){}
  
  info(text: string, callBack?: () => void){
    this.toastMessage(text, 'primary', callBack);
  }

  warning(text: string, callBack?: () => void){
    this.toastMessage(text, 'warning', callBack);
  }

  private async toastMessage(text: string, color: string, callBack?: () => void) {
    const toast = await this.toast.create({
      message: text,
      color: color,
      duration: 5000,
    });
    toast.onWillDismiss().then(() => {
      if(callBack) callBack();
    });
    toast.present();
  }
}