import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './confirm-training/login/login.page';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: ':club',
    children: [
      {
        path: 'login',
        component: LoginPage
      },
      {
        path: 'remadas',
        loadChildren: () => import('./confirm-training/invite.module').then(m => m.InviteModule)
      },
      /* {
        path: 'meus_dados',
        component: 
      }, */
      {
        path: 'remador',
        loadChildren: () => import('./users/form/cad-user.module').then(m => m.CadUserModule)
      }
    ]
  }
  /* {
    path: 'remadores',
    loadChildren: () => import('./users/form/cad-user.module').then(m => m.CadUserModule)
  } */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
