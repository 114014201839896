import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import { MessageBox } from 'src/app/componentes/helpers/message.box';
import { Club } from 'src/app/entities/club';
import { EssencialsComponent } from 'src/app/pages/essencials.component';
import { RowerLoginService } from 'src/app/services/rower/rower-login.service';

@Component({
  selector: 'app-lost-passw-user',
  templateUrl: 'lost-passw-user.page.html',
  styleUrls: ['lost-passw-user.page.scss']
})
export class LostPasswUserPage extends EssencialsComponent {
  
  form: FormGroup;
  generateCode: boolean = false;
  private club: string;

  constructor(private rowerLoginService: RowerLoginService, private fb: FormBuilder, protected modal: ModalController,
    protected message: MessageBox) {
    super();
    
    this.form = this.fb.group({
      email: [null, Validators.required]
    });
  }

  @Input() set data(value: {club: string}) {
    this.club = value.club;
  }

  send(){
    if(this.form.invalid) return;

    const email = this.form.get("email").value;

    if(this.generateCode){
      this.confirmNewPassw(email);
    }
    else {
      this.requestToken(email);
    }
    
  }

  protected confirmNewPassw(email: string){
    this.rowerLoginService.confirmTokenUpdatePassw(this.club, email, this.form.value,
      result => {
        this.modal.dismiss({code: "200", user: result});
      },
      error => {
        this.message.warning("O código utilizado é inválido.");
        this.removeTokenInput();
      }
    );
  }

  protected requestToken(email: string) {
    this.rowerLoginService.requestTokenUpdatePassw(this.club, email, 
      result => {
        this.enableTokenInput();
      },
      error => {
        this.message.warning("E-mail não encontrado neste clube.");
      }
    );
  }

  enableTokenInput() {
    if(this.generateCode) this.removeTokenInput();
    else this.addTokenInput();
  }

  protected addTokenInput() {
    this.form.addControl("code", new FormControl('', Validators.required));
    this.form.get("code").reset();
    //this.form.get("email").disable({onlySelf: true});
    this.generateCode = true;
  }

  protected removeTokenInput() {
    this.form.removeControl("code");
    //this.form.get("email").enable({onlySelf: true});
    this.generateCode = false;
  }

  close(){
    this.modal.dismiss();
  }

  getInstructions(): string {
    if(this.generateCode) return "Em alguns minutos, você receberá o código de verificação por e-mail. Copie e cole no campo abaixo.";
    return "Informe seu e-mail e receba o código de verificação.";
  }

}
