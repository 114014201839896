import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { LoadingController } from '@ionic/angular';
import { classToPlain } from 'class-transformer';
import { HttpByClub } from '../componentes/helpers/http-by-club';
import { MessageBox } from '../componentes/helpers/message.box';
import { Contract, FinancialSummary, FinancialUser, Payment, User, UserWithPhoto } from '../entities/user.model';


@Injectable()
export class UserService extends HttpByClub {
  
  constructor(private http: HTTP, client: HttpClient, loadingController: LoadingController, private msg: MessageBox){
    super(client, loadingController, msg);
  }

  list(result:(r: UserWithPhoto[]) => void): void {
    this.get<UserWithPhoto[]>("/api/users/", result);
  }

  save(user: User, result: (r: User) => void, error?: (e: any) => void): void {
    this.post<User>("/api/users/", classToPlain(user), result, error);
  }

  update(user: User, result: (r: User) => void, error?: (e: any) => void): void {
    this.put<User>("/api/users/", classToPlain(user), result, error);
  }

  login(credentials: {email: string, born: string}, result: (r: User) => void, error?: (e: any) => void): void {
    this.post<User>("/api/users/login", 
        classToPlain({email: credentials.email, born: credentials.born}), result, error);
  }

  savePayment(user: User, payment: Payment, result: (r: FinancialUser) => void, error?: (e: any) => void): void {
    const financial: FinancialUser = {
      user: user, 
      payments: [payment]
    };
    this.put<FinancialUser>("/api/users/add/payment", classToPlain(financial), result, error);
  }

  removePayment(user: User, payment: Payment, result: (r: FinancialUser) => void, error?: (e: any) => void): void {
    const financial: FinancialUser = {
      user: user, 
      payments: [payment]
    };
    this.put<FinancialUser>("/api/users/remove/payment", classToPlain(financial), result, error);
  }

  getPayment(user: User, result: (r: FinancialUser) => void, error?: (e: any) => void): void {
    this.get<FinancialUser>("/api/users/" + user.email + "/payment", result, error);
  }

  listAllPayments(year: string, result: (r: FinancialUser[]) => void, error?: (e: any) => void): void {
    this.get<FinancialUser[]>("/api/users/payment/" + year, result, error);
  }

  getSummary(yearMonth: string, result: (r: FinancialSummary) => void, error?: (e: any) => void): void {
    this.get<FinancialSummary>("/api/users/payment/" + yearMonth + "/summary", result, error);
  }

  getByEmail(user: User, result: (u: User) => void): void {
    this.get<User>("/api/users/" + user.email, result);
  }

  remove(email: string, result: (r: User) => void, error?: (e: any) => void): void {
    this.delete<User>("/api/users/" + email, {}, result, error);
  }

  getCurrentContract(email: string, thenMethod:(result: Contract) => void): void {
    this.get("/api/users/contract/" + email, thenMethod);
  }

}