import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { classToPlain } from 'class-transformer';
import { HttpGeneric } from 'src/app/componentes/helpers/http-generic';
import { MessageBox } from 'src/app/componentes/helpers/message.box';
import { User } from 'src/app/entities/user.model';

@Injectable()
export class RowerLoginService extends HttpGeneric {
  
  constructor(client: HttpClient, loadingController: LoadingController, msg: MessageBox){
    super(client, loadingController, msg);
  }

  login(credentials: {email: string, passw: string, club: string}, result: (r: User) => void, error?: (e: any) => void): void {
    this.post<User>("/" + credentials.club + "/api/users/login", 
        classToPlain({email: credentials.email, passw: credentials.passw}), result, error);
  }

  save(credentials: {user: User, club: string}, result: (r: User) => void, error?: (e: any) => void): void {
    this.post<User>("/" + credentials.club + "/api/users/", classToPlain(credentials.user), result, error);
  }

  update(credentials: {user: User, club: string}, result: (r: User) => void, error?: (e: any) => void): void {
    this.put<User>("/" + credentials.club + "/api/users/", classToPlain(credentials.user), result, error);
  }

  requestTokenUpdatePassw(club: string, email: string, thenMethod:(result: User) => void, errorMethod?:(error: any) => void): void {
    this.get("/" + club + "/api/users/" + email + "/newpassw", thenMethod, errorMethod);
  }

  confirmTokenUpdatePassw(club: string, email: string, data: {code: string}, thenMethod:(result: User) => void, errorMethod?:(error: any) => void): void {
    this.put("/" + club + "/api/users/" + email + "/newpassw/confirm", data, thenMethod, errorMethod);
  }

  changePassw(club: string, email: string, changeData: {email: string, older: string, newer: string}, thenMethod:(result: User) => void, errorMethod?:(error: any) => void): void {
    this.put("/" + club + "/api/users/" + email + "/changepassw", changeData, thenMethod, errorMethod);
  }

  forceNewPassw(club: string, email: string, changeData: {email: string, newer: string}, thenMethod:(result: User) => void, errorMethod?:(error: any) => void): void {
    this.put("/" + club + "/api/users/" + email + "/forcenewpassw", changeData, thenMethod, errorMethod);
  }

}