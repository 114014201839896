import { Injectable } from "@angular/core";
import { ActivatedRoute, CanLoad, Route, Router, UrlSegment, UrlTree } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { Observable } from "rxjs";
import { env } from '../../../envrs';
import { AuthGuardService } from "./auth-guard.service";

@Injectable()
export class LoadVersionLoadService implements CanLoad {

  constructor(public router: Router, public alert: AlertController, public route: ActivatedRoute, private authService: AuthGuardService) { }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const actualVersion = localStorage.getItem("version");
    
    if (!actualVersion || actualVersion == env.VERSION) {
      if(!actualVersion) this.register(env.VERSION);
      return true;
    }

    this.newVersionInfo();
    return false;
  }

  protected redirectLogin(): Promise<boolean>{
    this.authService.clear();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    return this.router.navigate(['login'], { relativeTo: this.route, queryParamsHandling: 'preserve', replaceUrl: true });
  }

  private register(version: string){
    localStorage.setItem("version", version);
  }
  
  protected async newVersionInfo(){
    const alert = await this.alert.create({
      header: 'Nova versão disponível',
      message: "Para continuar usando o app é necessário aceitá-la, clicando no botão abaixo.",
      buttons: [
        {
          text: 'Atualizar',
          cssClass: 'alert',
          handler: () => this.updateVersion()
        }
      ]
    });

    await alert.present();
  }

  protected updateVersion(){
    this.register(env.VERSION);
    this.redirectLogin();
  }

}