export class StringUtils {

  static trim(value: string, intern: boolean = true): string {
    if(StringUtils.isEmpty(value)) return null;
    let valueTrim = value.trim();

    if(intern){
      while(valueTrim.indexOf(" ") != -1) valueTrim = valueTrim.replace(" ", "");
    }
    return valueTrim;
  }

  static isEmpty(value: string): boolean{
    return value == null || value == undefined || value == "";
  }

  static isNotEmpty(value: string): boolean{
    return !StringUtils.isEmpty(value);
  }

  static contains(origin: string, partOrigin: string): boolean {
    if(StringUtils.isEmpty(partOrigin)) return true;
    return StringUtils.isEmpty(origin) ? false : origin.toLowerCase().includes(partOrigin.toLowerCase());
  }

  static toPluralText(value: number): string {
    return value == 1 ? "" : "s";
  }

}