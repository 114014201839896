import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { HttpByClub } from '../componentes/helpers/http-by-club';
import { HttpGeneric } from '../componentes/helpers/http-generic';
import { MessageBox } from '../componentes/helpers/message.box';
import { User } from '../entities/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardUserService extends HttpGeneric implements CanActivate{
  private static readonly KEY = "user-authenticated";

  private dateCalendarSelected: string;
  private clubSelected: string;
  
  constructor(private router: Router, client: HttpClient, loading: LoadingController, private msg: MessageBox) {
    super(client, loading, msg);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const data = route.params as {date: string, club: string};
    this.dateCalendarSelected = data.date;
    this.clubSelected = data.club;

    if (!this.isAuthorized) {
      this.msg.warning("Acesso não autorizado!");
      this.router.navigate([this.clubSelected + "/login"], {queryParams: {dateCalendar: this.dateCalendarSelected}});
      return false;
    }

    return true;
  }

  private authIsOk(): boolean {
    return localStorage.getItem(AuthGuardUserService.KEY) != null;
  }

  private authForThisClub(club: string): boolean {
    const authData = this.getAuthorized();
    if(!authData) return false;
    return authData.club == club;
  }

  logout(){
    localStorage.removeItem(AuthGuardUserService.KEY);
    this.router.navigate([this.clubSelected + "/login"], {queryParams: {dateCalendar: this.dateCalendarSelected}});
  }

  authorized(data: {user: User, club: string}){
    localStorage.setItem(AuthGuardUserService.KEY, JSON.stringify(data));
  }

  getAuthorized(): {user: User, club: string} {
    return Object.assign({} as User, JSON.parse(localStorage.getItem(AuthGuardUserService.KEY)));
  }

  validateToken(code: string, success:(r: {valid: boolean}) => void){
    this.get<{valid: boolean}>("/api/validatetoken/" + code, success);
  }

  updateUser(user: User){
    let auth = this.getAuthorized();
    auth.user = user;
    localStorage.setItem(AuthGuardUserService.KEY, JSON.stringify(auth));
  }

  get isAuthorized(): boolean {
    return this.authIsOk() && this.authForThisClub(this.clubSelected);
  }

}