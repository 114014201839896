import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { MessageBox } from 'src/app/componentes/helpers/message.box';
import { StringUtils } from 'src/app/componentes/helpers/string-utils';
import { User } from 'src/app/entities/user.model';
import { EssencialsComponent } from 'src/app/pages/essencials.component';
import { LostPasswUserPage } from 'src/app/pages/users/lost-passw/lost-passw-user.page';
import { UpdatePasswUserPage } from 'src/app/pages/users/update-passw/update-passw-user.page';
import { AuthGuardUserService } from 'src/app/services/auth-guard-user.service';
import { ClubService } from 'src/app/services/club.service';
import { RowerLoginService } from 'src/app/services/rower/rower-login.service';
import { RowerService } from 'src/app/services/rower/rower.service';

@Component({
  selector: 'confirm-training-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss']
})
export class LoginPage extends EssencialsComponent implements OnInit{

  form: FormGroup;
  _date: string;
  club: string;
  private _showPassw: boolean;

  constructor(private route: ActivatedRoute, private rowerLoginService: RowerLoginService, 
    private messageBox: MessageBox, private fb: FormBuilder, private modal: ModalController,
    private router: Router, private authUserService: AuthGuardUserService,
    private clubService: ClubService) {
    super();

    this.form = this.fb.group({
      email: [null, Validators.required],
      passw: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.club = this.route.snapshot.params.club;
    this._date = this.route.snapshot.queryParamMap.get("dateCalendar");
  }

  async login(){
    if(!this.formIsValid) return;

    let credentials: {email: string, passw: string} = this.form.value;
    credentials.email = StringUtils.trim(credentials.email).toLowerCase();

    this.rowerLoginService.login({...credentials, club: this.club}, 
                auth => {
                  if(auth.accessReset) this.confirmNewPassword(auth);
                  else this.confirmAccess(auth);
                }, 
                error => {
                  this.messageBox.warning("Remador não identificado.\nPor favor, contate o administrador para confirmar seu acesso.");
                  /* this.messageBox.warning(
                    "Remador não identificado.\nPor favor, contate o administrador para confirmar seu acesso.",
                    () => this.loginRedirect()); */
                });
  }

  private async confirmNewPassword(user: User){
    const modal = await this.modal.create({
      component: UpdatePasswUserPage,
      componentProps: { data: {user, club: this.club, type: "ForceNewPassw"} }
    });
    modal.onDidDismiss().then(result => {
      if(result && result.data == "200") this.confirmAccess(user);
    });
    await modal.present();
  }

  private confirmAccess(user: User){
    this.form.reset();
    this.authUserService.authorized({user: user, club: this.club});
    this.router.navigate([this.club + '/remadas/' + this.date]);
  }

  async requestNewPassw(){
    const modal = await this.modal.create({
      component: LostPasswUserPage,
      componentProps: { data: {club: this.club} }
    });
    modal.onDidDismiss().then(result => {
      if(!result.data) return;

      if(result.data.code == "200") {
        this.confirmNewPassword(result.data.user);
      }
      else if(result.data == "500") this.messageBox.warning("O e-mail não foi encontrado. Caso esteja com problemas, entre em contato com a gente!");
    });
    await modal.present();
  }

  goNewClub() {
    this.router.navigate(['novo-clube']);    
  }
  
  changeVisibility() {
    this._showPassw = !this._showPassw;
  }

  getPasswAccess(): string {
    return this._showPassw ? "eye-off-outline" : "eye-outline";
  }

  getPasswType(): string {
    return this._showPassw ? "text" : "password";
  }

  public get date(): string {
    return !this._date ? "" : this._date;
  }
  
}
