import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP } from '@ionic-native/http/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageBox } from './componentes/helpers/message.box';
import { CacheInterceptor } from './componentes/interceptors/http-interceptor';
import { LoginPage } from './confirm-training/login/login.page';
import { LoadVersionLoadService } from './services/load-version.load.service';
import { RowerLoginService } from './services/rower/rower-login.service';
import { UserService } from './services/user.service';

@NgModule({
  declarations: [AppComponent, LoginPage],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
    StatusBar,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    HTTP,
    MessageBox,
    LoadVersionLoadService,
    RowerLoginService,
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
