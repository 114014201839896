import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MessageBox } from 'src/app/componentes/helpers/message.box';
import { User } from 'src/app/entities/user.model';
import { EssencialsComponent } from 'src/app/pages/essencials.component';
import { RowerLoginService } from 'src/app/services/rower/rower-login.service';

@Component({
  selector: 'app-update-passw-user',
  templateUrl: 'update-passw-user.page.html',
  styleUrls: ['update-passw-user.page.scss']
})
export class UpdatePasswUserPage extends EssencialsComponent {
  
  private user: User;
  form: FormGroup;
  private type: "ForceNewPassw" | "UpdatePassw";
  club: string;

  constructor(private fb: FormBuilder, 
    protected message: MessageBox, private modal: ModalController,
    private rowerLoginService: RowerLoginService) {
    super();

    this.form = this.fb.group({
      email: [null, Validators.required],
      newer: [null, Validators.required],
      confirmNewer: [null, Validators.required]
    });
  }

  @Input() set data(value: {user: User, club: string, type: any}) {
    if(value.type != "ForceNewPassw"){
      this.form.addControl("older", new FormControl('', Validators.required));
    }

    this.user = value.user;
    this.type = value.type;
    this.club = value.club;
    this.get("email").setValue(this.user.email);
  }

  send(){
    const email = this.form.get("email").value;

    const data = this.form.value;
    if(data.newer != data.confirmNewer) {
      this.message.warning("Confirmação da senha está incorreta");
      return;
    }

    if(this.isForceNewPassw){
      this.updatePassw(email, data);
    }
    else {
      // TODO: Implementar
    }
  }

  close(data: any = "500"){
    this.modal.dismiss(data);
  }

  isForceNewPassw(): boolean {
    return this.type != 'ForceNewPassw';
  }

  protected updatePassw(email: string, data: any){
    this.rowerLoginService.forceNewPassw(this.club, email, data, 
      r => {
        this.message.info("Sua senha foi alterada");
        this.close("200");
      },
      error => this.message.warning("Não foi possível alterar a sua senha.<br>Confira os dados informados e, caso aconteça novamente, entre em contato com a gente!")
    );
  }

}
