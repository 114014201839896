import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{
  
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!localStorage.getItem("authenticated")) {
      this.redirectLogin();
      return false;
    }

    return true;
  }

  logout(){
    this.clear();
    this.redirectLogin();
  }

  clear(){
    localStorage.removeItem("authenticated");
  }

  private redirectLogin(){
    this.router.navigate(["login"], { replaceUrl: true });
  }

  authorized(credentials: AuthUser){
    localStorage.setItem("authenticated", JSON.stringify(credentials));
  }

  getAuthorized(): AuthUser {
    try {
      return JSON.parse(localStorage.getItem("authenticated"));
    }
    catch(error) {
      this.logout();
    }
  }

}

export class AuthUser {
  name: string;
  clubIdentifier: string;
  clubName: string;
}