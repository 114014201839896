import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { ServiceLocator } from './componentes/helpers/service-locator';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private injector: Injector
  ) {

    this.initializeApp();
  }

  initializeApp() {
    ServiceLocator.injector = this.injector;

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
    });
  }

}
