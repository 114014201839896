import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { classToPlain } from 'class-transformer';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessageBox } from './message.box';

export abstract class HttpGeneric {
  private static readonly PROCESS: string = "process";

  //private static processParallelCount: number = 0;
  private liveLoading: HTMLIonLoadingElement[] = [];
  protected useLoading: boolean = true;

  constructor(private client: HttpClient, private loadingController: LoadingController, private messageBox: MessageBox){ }

  protected getUrl():string{
    return environment.api;
  }

  protected post<T>(partialUrl: string, body: any, then:(result: T) => void, fail?:(error: any) => void): void {
    this.prepareRequest<T>(this.client.post<T>(this.getUrl() + partialUrl, classToPlain(body)), then, fail);
  }

  protected upload<T>(partialUrl: string, body: FormData, then:(result: T) => void, fail?:(error: any) => void): void {
    this.prepareRequest<T>(
      this.client.post<T>(this.getUrl() + partialUrl, body, {headers: {"Content-Type": "multipart/form-data"}}), 
      then, fail);
  }

  protected get<T>(partialUrl: string, then:(result: T) => void, fail?:(error: any) => void): void {
    this.prepareRequest<T>(this.client.get<T>(this.getUrl() + partialUrl), then, fail);
  }

  protected put<T>(partialUrl: string, body: any, then:(result: T) => void, fail?:(error: any) => void): void {
    this.prepareRequest<T>(this.client.put<T>(this.getUrl() + partialUrl, classToPlain(body)), then, fail);
  }

  protected delete<T>(partialUrl: string, body: any, then:(result: T) => void, fail?:(error: any) => void): void {
    this.prepareRequest<T>(this.client.delete<T>(this.getUrl() + partialUrl, classToPlain(body)), then, fail);
  }

  private prepareRequest<T>(observable: Observable<T>, then:(result: T) => void, fail:(error: any) => void): void{
    this.presentLoading(() => {
      try {
        this.invokeApi(observable, then, fail);
      } catch(error) {
        fail(error);
      }});
  }

  private invokeApi<T>(observable: Observable<T>, then:(result: T) => void, fail:(error: any) => void){
    observable.subscribe(
      (result: T) => {
        this.dismiss().then(() => then(result));
      },
      error => {
        this.dismiss().then(() => {
          const code = error.status;
          const message = error.error ?? error.message;
  
          console.log("[" + code + "] " + message);
          if(!fail) this.messageBox.warning(message);
          else fail({code, message});
        });
      }
    );
  }

  private async canShow(): Promise<boolean> {
    if(!this.useLoading) return false;
    const instance = await this.loadingController.getTop();
    return !instance || !instance.isConnected;
  }

  private async canHide(): Promise<boolean> {
    if(!this.useLoading) return false;
    const instance = await this.loadingController.getTop();
    return instance != null && instance.isConnected;
  }

  private async presentLoading(callback:() => void) {
    if(await this.canShow()) await this.show();
    //this.updateProcess(1);
    callback();
  }

  private async show() {
    const loading = await this.loadingController.create({
      cssClass: 'loading',
      spinner: "dots"
    });
    await loading.present();
  }

  private async dismiss(): Promise<void> {
    if(this.canHide) {
      try {
        await this.loadingController.dismiss();
      } catch (error){
        console.log("Caiu no erro! "+ error);
      }
      //this.updateProcess(-1);
    }
  }

 /*  private get processCounter(): number {
    const counter = localStorage.getItem(HttpGeneric.PROCESS);
    return (!counter || isNaN(parseInt(counter)) || parseInt(counter) < 0) ? 0 : parseInt(counter);
  }

  private updateProcess(increment: number) {
    const processCount = this.processCounter;
    localStorage.setItem(HttpGeneric.PROCESS, "" + (processCount + increment));
    console.log(`Process: ${this.processCounter}`)
  } */

}