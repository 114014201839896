import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { environment } from "src/environments/environment";
import { HttpGeneric } from "./http-generic";
import { MessageBox } from "./message.box";
import { ServiceLocator } from "./service-locator";

@Injectable({
  providedIn: 'root'
})
export abstract class HttpByClub extends HttpGeneric {

  private authService: AuthGuardService;

  constructor(client: HttpClient, loadingController: LoadingController, messageBox: MessageBox){
      super(client, loadingController, messageBox);

      this.authService = ServiceLocator.get(AuthGuardService);
  }

  protected getUrl(): string{
    const auth = this.authService.getAuthorized();

    if(!auth) return super.getUrl();
    return environment.api + "/" + auth.clubIdentifier;
  }
}