import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { StringUtils } from '../componentes/helpers/string-utils';
import { User } from '../entities/user.model';
import { env } from '../../../envrs';
import { Club } from '../entities/club';

export abstract class EssencialsComponent {

  static readonly MONTHS = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  static readonly MONTHS_SHORT = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
  static readonly WEEK_DAYS = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
  static readonly WEEK_DAYS_SHORT = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

  private _menuItems = [
    { link: '/', icon: 'home-outline', name: 'Início' },
    { link: '/app/contrato', icon: 'document-text-outline', name: 'Contrato atual' },
    { link: '/app/trocarsenha', icon: 'key-outline', name: 'Alterar senha' },
    { link: '/app/sobre', icon: 'information-circle-outline', name: 'Sobre', divider: true }
  ];

  abstract get form(): FormGroup;

  get formIsValid(): boolean {
    this.form.markAllAsTouched();
    this.validateAllFields(this.form);
    this.form.updateValueAndValidity({ onlySelf: true });
    return this.form.valid;
  }

  private validateAllFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(field => {  
        const control = formGroup.get(field);            
        if (control instanceof FormGroup || control instanceof FormArray) {        
            this.validateAllFields(control);
        }
        control.updateValueAndValidity({ onlySelf: true });
    });
  }

  hasError(field: string): boolean {
    const fieldForm: AbstractControl = this.form.get(field);
    return fieldForm.invalid && (fieldForm.dirty || fieldForm.touched);
  }

  static getNameExibition(user: User): string {
    if(user == null) return "...";
    return StringUtils.isEmpty(user.nickname) ? user.name : user.nickname;
  }

  isEmpty(name: string): boolean {
    return StringUtils.isEmpty(this.get(name).value);
  }

  isNotEmpty(name: string): boolean {
    return !this.isEmpty(name);
  }

  get(name: string): AbstractControl {
    return this.form.get(name);
  }

  version(): string {
    return env.VERSION;
  }

  menuItems(): { link: string, icon: string, name: string, divider?: boolean }[] {
    return this._menuItems;
  }
  
  get monthNames(): string[] {
    return EssencialsComponent.MONTHS;
  }

  get monthShortNames(): string[] {
    return EssencialsComponent.MONTHS_SHORT;
  }

}