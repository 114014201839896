import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { HttpGeneric } from '../componentes/helpers/http-generic';
import { MessageBox } from '../componentes/helpers/message.box';
import { Club, ClubSummary } from '../entities/club';
import { AuthGuardService, AuthUser } from './auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class ClubService extends HttpGeneric {
  
  constructor(private router: Router, client: HttpClient, loading: LoadingController, private msg: MessageBox, private authGuardClub: AuthGuardService) {
    super(client, loading, msg);
  }

  login(credentials: {email: string, passw: string}, thenMethod:(user: AuthUser) => void, fail?:(error: any) => void): void {
    this.post<{name: string}>("/api/login", credentials, thenMethod, fail);
  }

  logout(){
    //this.post<void>("/api/logout", {}, () => this.authGuardClub.logout());
    this.authGuardClub.logout();
  }

  list(thenMethod:(clubs: Club[]) => void): void {
    this.get("/api/clubs", thenMethod);
  }

  one(club: string, thenMethod:(result: Club) => void): void {
    this.get("/api/clubs/" + club, thenMethod);
  }

  generateAccessToken(club: string, thenMethod:(result: {code: string}) => void){
    this.get("/api/clubs/" + club + "/token", thenMethod);
  }

  getClubSummary(club: string, thenMethod:(result: ClubSummary) => void): void {
    this.get("/api/clubs/" + club + "/summary", thenMethod);
  }

  changePassw(email: string, changeData: {email: string, older: string, newer: string}, thenMethod:(result: Club) => void, errorMethod?:(error: any) => void): void {
    this.put("/api/clubs/" + email + "/changepassw", changeData, thenMethod, errorMethod);
  }

  save(club: Club, thenMethod:(result: ClubSummary) => void, errorMethod?:(result: any) => void){
    this.post("/api/clubs", club, thenMethod, errorMethod);
  }

  forceNewPassw(email: string, changeData: {email: string, newer: string}, thenMethod:(result: AuthUser) => void, errorMethod?:(error: any) => void): void {
    this.put("/api/clubs/" + email + "/forcenewpassw", changeData, thenMethod, errorMethod);
  }

  requestTokenUpdatePassw(email: string, thenMethod:(result: Club) => void, errorMethod?:(error: any) => void): void {
    this.get("/api/clubs/" + email + "/newpassw", thenMethod, errorMethod);
  }
  
  confirmTokenUpdatePassw(email: string, data: {code: string}, thenMethod:(result: Club) => void, errorMethod?:(error: any) => void): void {
    this.put("/api/clubs/" + email + "/newpassw/confirm", data, thenMethod, errorMethod);
  }

}
